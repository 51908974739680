import React from "react";

import Layout from "../../components/layout";
import {ApiDocSidebar} from "../../components/sidebar";
import {KeyHeader} from "../../components/typography";
import {CodeSnippet} from "../../components/snippet";
import {ServerLink} from "../../components/servers";

const pageTitle = "SQL Server Guide";

const Page = () => (
    <Layout pageTitle={pageTitle}
            sidebarNav={<ApiDocSidebar/>}
            mobileNav={false}
    >
        <KeyHeader className="mt-0">{pageTitle}</KeyHeader>

        <p>
            SQLAPI++ allows to work with a number of SQL database servers. It provides
            common mechanisms to access database, and as a general rule they work for any
            database server. But each server has some specific features which a developer has
            to know in order to leverage server's unique features and avoid potential errors.
        </p>

        <p>
            Working with Microsoft SQL Server using SQLAPI++ library depends on choosing an API.
            SQLAPI++ library supports the following interfaces to access SQL Server:
        </p>
        <ul>
            <li><ServerLink name="SQL Server (ODBC)">ODBC</ServerLink></li>
            <li><ServerLink name="SQL Server (OLEDB)">OLEDB</ServerLink></li>
        </ul>

        <p>
            Starting from version 4.0.3 SQLAPI++ library uses <strong>ODBC as default API</strong> for
            SQL Server. Please note that connection to SQL Server Compact Edition is
            possible with OLE DB API only. If for some reason you want to use OLE DB, you should set
            {' '}<code>UseAPI</code>{' '} connection option before specifying SQL Server client or connecting to
            database:
        </p>

        <CodeSnippet>{`
SAConnection con;
con.setOption(_TSA("UseAPI")) = _TSA("OLEDB");
con.setClient(SA_SQLServer_Client);
con.Connect(...);
        `.trim()}
        </CodeSnippet>

        <p>
            For more connection details see {' '}<ServerLink linkType="connect">SQL Server (ODBC)</ServerLink>{' '} or
            {' '}<ServerLink linkType="connect">SQL Server (OLEDB)</ServerLink> specific information.
        </p>
    </Layout>
);

export default Page;
